import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import AOS from 'aos';

import 'aos/dist/aos.css';
import "./index.css";
import "./App.css";
import "./style/Font.css";
import "./style/Config.css";
import "./style/Scrollbar.css";
import "./style/Markdown.css";
import "./style/Animation.css";
import "./components/floating bar/Floating.css";

import About from "./pages/about/About";
import UiuxProject from "./pages/project/uiux/Project";
import UiuxSingleProject from "./pages/project/uiux/SingleProject";
import FrontendProject from "./pages/project/frontend/Project";
import FrontendSingleProject from "./pages/project/frontend/SingleProject";
import QaProject from "./pages/project/qa/Project";
import QaSingleProject from "./pages/project/qa/SingleProject";
import Code from "./pages/code/Code";
import SingleCode from "./pages/code/SingleCode";
import Offline from "./pages/maintenance/Offline";
import PageNotFound from "./pages/maintenance/404Page";
import WebsiteStatus from "./components/website status/WebsiteStatus";
import SingleWork from "./pages/singlework/SingleWork";
import UnderMaintenance from "./pages/maintenance/UnderMaintenace";

function App() {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [networkStatus, setNetworkStatus] = useState("");

    AOS.init();

    useEffect(() => {
        const handleNetworkChange = () => {
            setIsOnline(navigator.onLine);
            setNetworkStatus(navigator.onLine ? "" : "offline");
        };

        window.addEventListener("online", handleNetworkChange);
        window.addEventListener("offline", handleNetworkChange);

        return () => {
            window.removeEventListener("online", handleNetworkChange);
            window.removeEventListener("offline", handleNetworkChange);
        };
    }, []);

    return (
        <div>
            {networkStatus === "offline" && <Offline />}
            {isOnline && networkStatus === "" && (
                <Router>
                    <Routes>
                        <Route path="/" element={<About />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/work/:slug" element={<SingleWork />} />
                        <Route path="/uiux" element={<UiuxProject />} />
                        <Route path="/uiux/:slug" element={<UiuxSingleProject />} />
                        <Route path="/frontend" element={<FrontendProject />} />
                        <Route path="/frontend/:slug" element={<FrontendSingleProject />} />
                        <Route path="/qa" element={<QaProject />} />    
                        <Route path="/qa/:slug" element={<QaSingleProject />} />
                        <Route path="/insight/:slug" element={<SingleCode />} />
                        <Route path="/insight" element={<Code />} />
                        <Route path="*" element={<Navigate replace to="/404" />} />
                        <Route path="/404" element={<PageNotFound />} />
                    </Routes>
                </Router>
            )}
            <WebsiteStatus show="" />
        </div>
    );
}

export default App;
