import React from "react";

const SingleProjectHeader = ({ singleArticles }) => {
    return (
        <div className="flex flex-col gap-10 lg:gap-20 pt-40">
            <section className="flex flex-col lg:flex-row justify-between gap-5 px-5 md:px-10 xl:px-24">
                <div data-aos="fade-right" className="flex flex-col">
                    <div className="flex flex-wrap gap-x-3">
                        {singleArticles.fields.tag.map((tag, i) => (
                            <p
                                key={i}
                                className="text-gray-500 rounded-full pr-2 py-1 dmsans-semibold text-[10px] uppercase tracking-wide"
                            >
                                {tag}
                            </p>
                        ))}
                    </div>
                    <p className="dmsans-semibold text-[24px] md:text-[39px] lg:text-[47px]">
                        {singleArticles.fields.title}
                    </p>
                </div>
                <div  data-aos="fade-left" className="flex flex-col items-end gap-5 w-full lg:w-[40%]">
                    <p className="dmsans text-left lg:text-right leading-7 duration-300">
                        {singleArticles.fields.outline}
                    </p>
                    <div className="bg-gray-200 w-64 h-px"></div>
                </div>
            </section>
            <section className="flex justify-center px-5 md:px-10">
                {(singleArticles.fields.device === "Web" || singleArticles.fields.device === "Mobile") && singleArticles.fields.headerMockup && (
                    <img
                        src={`https:${singleArticles.fields.headerMockup.fields.file.url}`}
                        alt=""
                        data-aos="fade-up"
                        className={`w-full ${singleArticles.fields.device === "Web" ? "lg:w-3/4" : "lg:w-1/2"} h-full ${singleArticles.fields.device === "Web" ? "shadow-2xl shadow-slate-100 rounded-[20px]" : ""}`}
                    />
                )}
            </section>
        </div>
    );
};

export default SingleProjectHeader;
