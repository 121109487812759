import React, { useEffect, useState } from "react";
import client from "../../../client";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom

import BadRequest from "../../maintenance/400Page";
import PageNotFound from "../../maintenance/404Page"; // Import PageNotFound component

function Work() {
    const [articles, setArticles] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Initialize useNavigate

    const fetchData = async () => {
        try {
            const response = await client.getEntries({
                content_type: "work",
            });
            const sortedArticles = response.items.sort(
                (a, b) =>
                    new Date(b.fields.startDate) - new Date(a.fields.startDate)
            );
            setArticles(sortedArticles);
        } catch (err) {
            setError(err);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (error) return <BadRequest />;

    return (
        <>
            <div
                data-aos="fade-up"
                data-aos-delay="100"
                className="flex flex-col lg:flex-row gap-2 ml-[-1rem]"
            >
                {articles.map((article, i) => (
                    <Link
                        to={`/work/${article.fields.slug}`}
                        key={i}
                        className="flex flex-col gap-[2px] hover:bg-amber-100 px-4 py-2 rounded-md duration-200 w-max"
                        onClick={() => {
                            if (!article.fields.slug) {
                                navigate('/404');
                            }
                        }}
                    >
                        <div className="flex items-center gap-3">
                            <div className="block lg:hidden bg-gray-300 w-3 h-3 rounded-full"></div>
                            <div>
                                <div className="flex items-center gap-2 whitespace-nowrap">
                                    <p className="dmsans-semibold">
                                        {article.fields.position}
                                    </p>
                                    {article.fields.present === true && (
                                        <p className="dmsans-semibold bg-green-100 px-1 py-[.1rem] text-green-800 text-[0.7rem] uppercase rounded-md">
                                            Current
                                        </p>
                                    )}
                                </div>
                                <p className="dmsans text-[0.9rem]">
                                    {article.fields.companyName}
                                </p>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </>
    );
}

export default Work;
