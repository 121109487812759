import React, { useEffect, useState } from "react";
import client from "../../../client";
import BadRequest from "../../maintenance/400Page";

function AnotherWork({ anotherWork }) {
    const [articles, setArticles] = useState([]);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        try {
            const response = await client.getEntries({
                content_type: "work",
            });
            const sortedArticles = response.items.sort(
                (a, b) => new Date(b.fields.startDate) - new Date(a.fields.startDate)
            );
            setArticles(sortedArticles);
        } catch (err) {
            setError(err);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    
    if (error) return <BadRequest />;

    return (
        <div className="flex flex-col gap-5 mx-5 md:mx-32">
            <p className="dmsans-semibold text-gray-400 uppercase text-[1.25rem] tracking-wider">
                Other experiences
            </p>
            {articles.map((article, i) =>
                article.fields.slug !== anotherWork ? (
                    <a
                        href={`/work/${article.fields.slug}`}
                        key={i}
                        className="hover:ml-5 duration-200 w-fit"
                    >
                        <p className="dmsans-semibold text-slate-900 text-[0.9rem] sm:text-[1.1rem] lg:text-[1.3rem]">
                            {article.fields.companyName}
                        </p>
                        <p className="dmsans-medium text-[0.9rem] text-gray-400">
                            {article.fields.position}
                        </p>
                    </a>
                ) : null
            )}
        </div>
    );
}

export default AnotherWork;
