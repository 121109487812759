import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import home_icon from "../../asset/icon/home-simple-door.svg";
import home_icon2 from "../../asset/icon/home-simple.svg";
import project_icon from "../../asset/icon/cube.svg";
import project_icon2 from "../../asset/icon/cube-hole.svg";
import book_icon from "../../asset/icon/book.svg";
import book_icon2 from "../../asset/icon/open-book.svg";

export default function Navbar({ position }) {
    const [showNavbar, setShowNavbar] = useState(true);
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        let timeout;
        if (showNavbar) {
            timeout = setTimeout(() => {
                setShowNavbar(false);
            }, 60000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [showNavbar]);

    const handleMouseEnter = () => {
        setShowNavbar(true);
        setShowDropdown(true);
    };

    const handleMouseLeave = () => {
        setShowNavbar(false);
        setShowDropdown(false);
    };

    const getPositionClasses = () => {
        if (position === "bottom") {
            return "fixed bottom-10 left-1/2 -translate-x-1/2";
        }
        return "fixed left-10 top-1/2 -translate-y-1/2";
    };

    const getLayoutClasses = () => {
        if (position === "bottom") {
            return "flex-row";
        }
        return "flex-col";
    };

    return (
        <div
            className={`${getPositionClasses()} flex ${getLayoutClasses()} gap-2 bg-white rounded-xl z-20 shadow-lg shadow-black/10`}
        >
            <Link
                to="/about"
                className="group p-3 hover:bg-gray-100 rounded-xl duration-100"
            >
                <img
                    src={home_icon}
                    alt=""
                    srcSet=""
                    width={22}
                    className="group-hover:hidden duration-200"
                />
                <img
                    src={home_icon2}
                    alt=""
                    srcSet=""
                    width={22}
                    className="hidden group-hover:block duration-200"
                />
            </Link>
            <div
                className="p-3 hover:bg-gray-100 rounded-xl duration-100 relative group"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <img
                    src={project_icon}
                    alt=""
                    srcSet=""
                    width={22}
                    className="group-hover:hidden duration-200"
                />
                <img
                    src={project_icon2}
                    alt=""
                    srcSet=""
                    width={22}
                    className="hidden group-hover:block duration-200"
                />
                <div
                    className={`absolute ${
                        position === "bottom"
                            ? "bottom-full left-1/2 -translate-x-1/2"
                            : "left-full top-1/2 -translate-y-1/2"
                    } bg-transparent ${showDropdown ? "visible" : "invisible"}`}
                >
                    <div
                        className={`${
                            position === "bottom" ? "mb-2" : "ml-2"
                        } opacity-0 transform ${
                            position === "bottom"
                                ? "-translate-y-2"
                                : "-translate-x-2"
                        } transition-all duration-300 ease-in-out ${
                            showDropdown
                                ? "opacity-100 translate-y-0 translate-x-0"
                                : ""
                        }`}
                    >
                        <ul
                            className={`space-x-2 whitespace-nowrap ${
                                position === "bottom"
                                    ? "flex space-x-2 space-y-0"
                                    : ""
                            }`}
                        >
                            <Link
                                to="/qa"
                                className={`bg-white shadow-md rounded-md py-2 px-4 hover:bg-gray-100 dmsans-semibold text-[14px] transition-opacity duration-200 ${
                                    showDropdown ? "opacity-100" : "opacity-0"
                                }`}
                            >
                                Quality Assurance
                            </Link>
                            <Link
                                to="/frontend"
                                className={`bg-white shadow-md rounded-md py-2 px-4 hover:bg-gray-100 dmsans-semibold text-[14px] transition-opacity duration-200 ${
                                    showDropdown ? "opacity-100" : "opacity-0"
                                }`}
                            >
                                Front End
                            </Link>
                            <Link
                                to="/uiux"
                                className={`bg-white shadow-md rounded-md py-2 px-4 hover:bg-gray-100 dmsans-semibold text-[14px] transition-opacity duration-200 ${
                                    showDropdown ? "opacity-100" : "opacity-0"
                                }`}
                            >
                                UI/UX
                            </Link>
                        </ul>
                    </div>
                </div>
            </div>
            <Link
                to="/insight"
                className="group p-3 hover:bg-gray-100 rounded-xl duration-100"
            >
                <img
                    src={book_icon}
                    alt=""
                    srcSet=""
                    width={22}
                    className="group-hover:hidden duration-200"
                />
                <img
                    src={book_icon2}
                    alt=""
                    srcSet=""
                    width={22}
                    className="hidden group-hover:block duration-200"
                />
            </Link>
        </div>
    );
}