import React from "react";
import dribbble_icon from "../../../asset/icon/dribbble.webp";

const SingleProjectPublish = ({ singleArticles }) => {
    const renderProjectLink = () => {
        switch (singleArticles.fields.liveStatus) {
            case "Design Published":
                return (
                    <a
                        href={singleArticles.fields.hyperlink}
                        target="_blank"
                        rel="noreferrer"
                        data-aos="fade-up"
                        className="flex items-center gap-8 dmsans-medium shadow-[0px_0px_10px_5px_rgba(0,0,0,0.05)] hover:shadow-[0px_0px_25px_6px_rgba(254,205,211,0.5)] duration-200 px-10 py-3 rounded-3xl w-max cursor-pointer"
                    >
                        <div className="flex flex-col">
                            <p className="text-[13px] text-slate-600">Check on</p>
                            <span className="dmsans-semibold text-[16px] whitespace-nowrap">
                                {singleArticles.fields.linkname}
                            </span>
                        </div>
                        <img
                            src={dribbble_icon}
                            alt="dribbble icon"
                            srcSet=""
                            width={24}
                        />
                    </a>
                );
            case "Website Hosted":
                return (
                    <a
                        href={singleArticles.fields.hyperlink}
                        target="_blank"
                        rel="noreferrer"
                        data-aos="fade-up"
                        data-aos-delay="100"
                        className="button-animation flex items-center gap-2 dmsans-medium text-[16px] bg-white after:bg-emerald-200 text-black border-2 border-emerald-200 px-10 py-3 rounded-full w-max cursor-pointer"
                    >
                        <span className="relative flex h-3 w-3">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-emerald-400 opacity-75"></span>
                            <span className="relative inline-flex rounded-full h-3 w-3 bg-emerald-500"></span>
                        </span>
                        <span className="dmsans-medium text-emerald-600 whitespace-nowrap">
                            Live in production
                        </span>
                    </a>
                );
            default:
                return null;
        }
    };

    return (
        <div className="flex justify-center">
            {renderProjectLink()}
        </div>
    );
};

export default SingleProjectPublish;
