import React, { useEffect, useState } from "react";
import "lazysizes";
import client from "../../../client";

import { Link } from "react-router-dom";
import FloatingBar from "../../../components/floating bar/FloatingBar";
import BadRequest from "../../maintenance/400Page";
import SEO from "../../../components/seo/SEO";
import ReactLenis from "lenis/react";
import LoadingPage from "../../../components/loading/loadingPage";

function Project() {
    const [articles, setArticles] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.lazySizes.init();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await client.getEntries({
                    content_type: "frontend",
                });
                setArticles(
                    response.items.sort(
                        (a, b) =>
                            new Date(b.fields.date) - new Date(a.fields.date)
                    )
                );
            } catch (err) {
                setError(err);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    if (isLoading) return <LoadingPage />;

    if (error) return <BadRequest />;

    return (
        <ReactLenis root>
            <SEO
                title="Bring ideas to life with sleek web development and dynamic, engaging interfaces."
                keywords="Cahya, Cahya Putra, Cahya Putra Ugira, Project, Project Collection, Mini Project"
                author="Cahya Putra Ugira"
                url="https://rafaelcahya.space/project"
            />
            <FloatingBar position="bottom" />
            <div className="flex flex-col items-center gap-20 md:gap-32 pt-32 xs:pt-40 md:pt-44 pb-56">
                <p className="dmsans-medium text-slate-900 dark:text-slate-100 text-[24px] xs:text-[31px] lg:text-[39px] text-center px-5 sm:px-10 w-full md:w-[50rem] duration-300">
                    Bring ideas to life with sleek web development and dynamic,{" "}
                    <span className="text-transparent bg-clip-text bg-gradient-to-r from-amber-400 to-purple-400">
                        engaging interfaces
                    </span>
                </p>
                <section className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-14 mx-5 xs:mx-10 lg:mx-16 xl:mx-24 my-10">
                    {articles.map((article) => (
                        <Link
                            to={`/frontend/${article.fields.slug}`}
                            key={article.sys.id}
                            className="outline-none rounded-xl"
                        >
                            {article.fields.thumbnailImage && (
                                <div className="group flex flex-col gap-5 duration-200">
                                    <div className="rounded-xl group-hover:shadow-2xl group-hover:shadow-gray-200 group-hover:scale-110 duration-200">
                                        <img
                                            data-src={
                                                "https:" +
                                                article.fields.thumbnailImage
                                                    .fields.file.url
                                            }
                                            data-srcset={
                                                article.fields.thumbnailImage
                                                    .fields.file.url
                                            }
                                            data-sizes="auto"
                                            alt={
                                                article.fields.thumbnailImage
                                                    .fields.title
                                            }
                                            className="lazyload rounded-xl"
                                            loading="lazy"
                                            decoding="async"
                                        />
                                    </div>
                                    <div className="flex flex-col gap-1 w-full md:whitespace-nowrap">
                                        <p className="dmsans-semibold text-[1rem] xl:text-[1.15rem] text-slate-900">
                                            {article.fields.title}
                                        </p>
                                        <div className="flex flex-wrap gap-x-5 gap-y-1">
                                            {article.fields.tags.map(
                                                (tag, i) => (
                                                    <p
                                                        key={i}
                                                        className="dmsans-medium text-[12px] xl:text-[14px] text-slate-500 dark:text-slate-400 duration-300 whitespace-nowrap"
                                                    >
                                                        {tag}
                                                    </p>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Link>
                    ))}
                </section>
            </div>
        </ReactLenis>
    );
}

export default Project;
