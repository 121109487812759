import React from "react";
import FloatingBar from "../../components/floating bar/FloatingBar";
import Profile from "./component/Profile.jsx";
import ErrorBoundary from "../../components/errorBoundary.jsx";

import Work from "./component/Work.jsx";
import Contact from "./component/Contact.jsx";
import ReactLenis from "lenis/react";
import SEO from "../../components/seo/SEO.jsx";

const About = () => {
    return (
        <ErrorBoundary>
            <ReactLenis root>
                <SEO title="Cahya Putra Ugira" />
                <div className="min-h-screen mt-40 mb-28 px-5 md:px-16 xl:px-44 duration-300">
                    <FloatingBar position={window.innerWidth <= 1280 ? "bottom" : ""}/>
                    <div className="flex flex-col gap-32">
                        <div className="flex flex-col gap-20">
                            <Profile />
                            <Work />
                        </div>
                        <Contact />
                    </div>
                </div>
            </ReactLenis>
        </ErrorBoundary>
    );
};

export default About;
