import React, { useEffect, useState } from "react";
import client from "../../client";
import { Link } from "react-router-dom";

import FloatingBar from "../../components/floating bar/FloatingBar";
import BadRequest from "../maintenance/400Page";
import SEO from "../../components/seo/SEO";
import ReactLenis from "lenis/react";
import LoadingPage from "../../components/loading/loadingPage";

const sortByDateDescending = (a, b) => new Date(b.fields.date) - new Date(a.fields.date);

function Code() {
    const [articles, setArticles] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await client.getEntries({
                    content_type: "uniqueCode",
                });
                setArticles(response.items.sort(sortByDateDescending));
            } catch (err) {
                setError(err);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    if (isLoading) return <LoadingPage />;

    if (error) return <BadRequest />;

    return (
        <ReactLenis root>
            <SEO
                title="Being up-to-date with coding and design is essential"
                keywords="Cahya, Cahya Putra, Cahya Putra Ugira, Code, UI Design, Insight, Blog"
                author="Cahya Putra Ugira"
                url="https://rafaelcahya.space/insight"
            />
            <FloatingBar position="bottom"/>
            <div className="flex flex-col gap-32 pt-32 xs:pt-40 md:pt-44 pb-56 duration-300">
                <div className="flex justify-center">
                    <p className="dmsans-medium text-slate-900 dark:text-slate-100 duration-300 text-[24px] xs:text-[31px] lg:text-[39px] text-center px-5 sm:px-10 w-full md:w-[60rem]">
                        Being{" "}
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-amber-400 to-purple-400">
                            up-to-date
                        </span>{" "}
                        with coding and design is essential
                    </p>
                </div>
                <div className="flex flex-wrap justify-center gap-14 px-5">
                    {articles.map((article) => (
                        <Link
                            to={`/insight/${article.fields.slug}`}
                            key={article.sys.id}
                            data-aos="fade-right"
                            className="w-full md:w-2/5 xl:w-1/3 outline-none"
                        >
                            <div className="hover-multiline-underline group flex flex-col gap-5 h-full">
                                <div className="flex-1 flex flex-col gap-2 h-full">
                                    <p className="group-hover:text-blue-500 dmsans-semibold text-[1.25rem] text-slate-900 dark:text-slate-100 duration-300">
                                        {article.fields.title}
                                    </p>
                                    <p className="dmsans text-[0.9rem] text-slate-600 dark:text-slate-400 duration-300 leading-6 line-clamp-3">
                                        {article.fields.description}
                                    </p>
                                </div>
                                <span className="hover-multiline-underline-pb-2 hover-multiline-underline-thickness-px tracking-wider dmsans-medium mt-5 text-slate-900 dark:text-slate-100 text-[14px] w-fit">
                                    Continue reading
                                </span>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </ReactLenis>
    );
}

export default Code;
