import React, { useEffect, useState } from "react";
import "lazysizes";
import client from "../../../client";

function Profile() {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await client.getEntries({
                    content_type: "profile",
                });
                setArticles(response.items);
            } catch (error) {
                console.error("Failed to fetch articles:", error);
            }
        };

        fetchArticles();
    }, []);

    return (
        <>
            {articles.map((article, i) => (
                <div
                    key={i}
                    className="flex flex-col gap-5 sm:gap-20 md:gap-10 lg:gap-16 z-10"
                >
                    <div data-aos="fade-up">
                        <p className="dmsans-semibold text-gray-300 text-[2.5rem] md:text-[4.32rem] lg:text-[5.2rem]">
                            {article.fields.name}.
                        </p>
                        <p className="dmsans-bold text-black text-[2.5rem] md:text-[4.2rem] lg:text-[5.2rem] mt-0 sm:mt-4 leading-[3rem]">
                            {article.fields.currentPosition}.
                        </p>
                    </div>
                </div>
            ))}
        </>
    );
}

export default Profile;
