import React, { useEffect, useState } from "react";
import client from "../../client";
import { useParams } from "react-router-dom";
import moment from "moment";

import FloatingBar from "../../components/floating bar/FloatingBar";
import BadRequest from "../maintenance/400Page";
import AnotherWork from "./component/AnotherWork";
import ReactLenis from "lenis/react";
import LoadingPage from "../../components/loading/loadingPage";
import PageNotFound from "../maintenance/404Page";

export function withRouter(Children) {
    return ({ match, ...props }) => {
        const updatedMatch = { params: useParams(), ...match };
        return <Children {...props} match={updatedMatch} />;
    };
}

function SingleWork({ match }) {
    const [singleArticles, setSingleArticles] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await client.getEntries({
                    content_type: "work",
                    "fields.slug": match?.params.slug,
                });
                setSingleArticles(response.items[0]);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };
        fetchData();
    }, [match?.params.slug]);

    const calculateWeeks = (startDate, endDate) => {
        const start = moment(startDate);
        const end = endDate ? moment(endDate) : moment();
        return end.diff(start, "weeks");
    };

    if (error) return <BadRequest />;

    if (loading) return <LoadingPage />;

    if (!singleArticles) return <PageNotFound />;
    return (
        <ReactLenis root>
            <FloatingBar position="bottom" />
            <div className="flex flex-col gap-14 xl:gap-44 my-32">
                <div className="flex flex-col lg:flex-row justify-between gap-10 lg:gap-32 mx-5 md:mx-10 lg:mx-16 xl:mx-24">
                    <div className="flex flex-col gap-5 w-full lg:w-1/2">
                        <p
                            data-aos="fade-up"
                            className="dmsans-bold text-[2.5rem]"
                        >
                            {singleArticles.fields.companyName}
                        </p>
                        <p
                            data-aos="fade-up"
                            data-aos-delay="100"
                            className="dmsans-medium text-[0.9rem] lg:text-[1rem] xl:text-[1.05rem] leading-6 xl:leading-8 lg:leading-7"
                        >
                            {singleArticles.fields.brief}
                        </p>
                    </div>
                    <div className="flex flex-col md:flex-row lg:flex-col justify-between gap-5 border-t border-b border-gray-200 py-10 w-full lg:w-1/4">
                        <div data-aos="fade-up" className="flex flex-col gap-2">
                            <p className="dmsans-semibold text-gray-400 uppercase text-[1rem] tracking-wider">
                                Position
                            </p>
                            <p className="dmsans-medium text-[0.9rem] lg:text-[1rem] xl:text-[1.05rem]">
                                {singleArticles.fields.position}
                            </p>
                        </div>
                        <div
                            data-aos="fade-up"
                            data-aos-delay="150"
                            className="flex flex-col gap-2"
                        >
                            <p className="dmsans-semibold text-gray-400 uppercase text-[1rem] tracking-wider">
                                Team
                            </p>
                            <p className="dmsans-medium text-[0.9rem] lg:text-[1rem] xl:text-[1.05rem]">
                                {singleArticles.fields.team}
                            </p>
                        </div>
                        <div
                            data-aos="fade-up"
                            data-aos-delay="200"
                            className="flex flex-col gap-2"
                        >
                            <p className="dmsans-semibold text-gray-400 uppercase text-[1rem] tracking-wider">
                                Timeline
                            </p>
                            <p className="dmsans-medium text-[0.9rem] lg:text-[1rem] xl:text-[1.05rem]">
                                {calculateWeeks(
                                    singleArticles.fields.startDate,
                                    singleArticles.fields.endDate
                                )}{" "}
                                weeks
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center gap-20 mx-5 md:mx-32">
                    <p className="dmsans-semibold text-gray-400 uppercase text-[1.25rem] tracking-wider">
                        Story
                    </p>
                    <div className="flex flex-col gap-10 lg:gap-20 w-full xl:w-3/4 mx-auto">
                        {singleArticles.fields.professionalJourney.map(
                            (pj, i) => (
                                <div
                                    key={i}
                                    data-aos="fade-up"
                                    className="flex flex-col lg:flex-row gap-2"
                                >
                                    <span className="w-full lg:w-3/5">
                                        <p className="dmsans-bold text-[1rem] xl:text-[1.05rem] w-full md:w-1/2">
                                            {pj.fields.title}
                                        </p>
                                    </span>
                                    <p className="dmsans-medium text-[0.9rem] lg:text-[1rem] xl:text-[1.05rem] w-full xs:w-3/4 lg:w-2/5">
                                        {pj.fields.description}
                                    </p>
                                </div>
                            )
                        )}
                    </div>
                </div>
                <div className="flex flex-col gap-5 mx-5 md:mx-32">
                    <p className="dmsans-semibold text-gray-400 uppercase text-[1.25rem] tracking-wider">
                        Gained Expertise
                    </p>
                    <p
                        className="dmsans-medium text-[0.9rem] lg:text-[1rem] xl:text-[1.05rem] leading-6 xl:leading-8 w-full md:w-4/5"
                        data-aos="fade-up"
                    >
                        {singleArticles.fields.gainedExpertise}
                    </p>
                </div>
                <div className="flex flex-col gap-5 mx-5 md:mx-32">
                    <p className="dmsans-semibold text-gray-400 uppercase text-[1.25rem] tracking-wider">
                        Skills
                    </p>
                    <div className="flex flex-wrap gap-3" data-aos="fade-up">
                        {singleArticles.fields.skills.map((skill, i) => (
                            <p
                                key={i}
                                className="dmsans-medium text-[0.9rem] lg:text-[1rem] xl:text-[1.05rem] bg-gray-100 px-4 py-2 rounded-full"
                            >
                                {skill}
                            </p>
                        ))}
                    </div>
                </div>
                <AnotherWork anotherWork={singleArticles.fields.slug} />
            </div>
        </ReactLenis>
    );
}

export default withRouter(SingleWork);
