import React from "react";
import Markdown from "../../../../helper/markdown/Markdown";

const SingleProjectDetails = ({ singleArticles }) => {
    return (
        <>
            <div className="flex flex-col gap-40 px-3 md:px-10">
                <section className="markdown flex flex-col gap-7 mx-auto dmsans text-[1rem] text-slate-700 leading-8 duration-300">
                    <Markdown>{singleArticles.fields.testcase}</Markdown>
                </section>
                <section className="flex flex-col items-center gap-20 px-5 md:px-10 lg:px-32 xl:px-80">
                    <p className="dmsans-semibold text-gray-400 uppercase text-[1.25rem] tracking-wider">
                        testing coverage
                    </p>
                    <div className="flex flex-col gap-10 lg:gap-20 mx-auto">
                        {singleArticles.fields.testingCoverage.map((tc, i) => (
                            <div
                                key={i}
                                data-aos="fade-up"
                                className="flex flex-col lg:flex-row gap-2"
                            >
                                <span className="w-full lg:w-3/5">
                                    <p className="dmsans-bold text-[1rem] xl:text-[1.05rem] w-full md:w-1/2">
                                        {tc.fields.title}
                                    </p>
                                </span>
                                <p className="dmsans-medium text-[0.9rem] lg:text-[1rem] xl:text-[1.05rem] w-full xs:w-3/4 lg:w-2/5">
                                    {tc.fields.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
        </>
    );
};

export default SingleProjectDetails;
