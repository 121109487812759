import React from "react";

const SingleProjectFooter = ({ singleArticles }) => {
    return (
		<div>
			<p className="dmsans-medium text-center pt-40">{singleArticles.fields.title}</p>
		</div>
    );
};

export default SingleProjectFooter;
