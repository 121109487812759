import React, { useEffect, useState } from "react";
import client from "../../../client";

export default function Contact() {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await client.getEntries({ content_type: "profile" });
                setArticles(response.items);
            } catch (error) {
                console.error("Failed to fetch articles:", error);
            }
        };

        fetchArticles();
    }, []);

    const renderContact = (contact, index) => {
        const isEmail = contact.fields.title === "Email";
        const href = isEmail ? `mailto:${contact.fields.hyperlink}` : contact.fields.hyperlink;
        const src = `https:${contact.fields.logo.fields.file.url}`;
        return (
            <a href={href} key={index}>
                <img src={src} alt={contact.fields.title} className="w-[40px]" />
            </a>
        );
    };

    return (
        <>
            {articles.map((article, i) => (
                <div data-aos="fade-up" data-aos-delay="200" className="flex flex-col gap-5" key={i}>
                    <p className="dmsans-semibold text-[1.5rem]">
                        Let's Get in Touch
                    </p>
                    <div className="flex gap-4">
                        {article.fields.contact.map((contact, i) => renderContact(contact, i))}
                    </div>
                </div>
            ))}
        </>
    );
}
