import React from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

const SingleProjectDetails = ({ singleArticles }) => {
    React.useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div className="flex flex-col gap-10 lg:gap-20 pt-40">
            <section className="flex flex-col items-center gap-20 mx-5 md:mx-32">
                <p className="dmsans-semibold text-gray-400 uppercase text-[1.25rem] tracking-wider">
                    Story
                </p>
                <div className="flex flex-col gap-10 lg:gap-20 w-full xl:w-3/4 mx-auto">
                    {singleArticles.fields.componentFrontend.map((pj, i) => (
                        <div
                            key={i}
                            data-aos="fade-up"
                            className="flex flex-col lg:flex-row gap-2"
                        >
                            <span className="w-full lg:w-3/5">
                                <p className="dmsans-bold text-[1rem] xl:text-[1.05rem] w-full md:w-1/2">
                                    {pj.fields.title}
                                </p>
                            </span>
                            <p className="dmsans-medium text-[0.9rem] lg:text-[1rem] xl:text-[1.05rem] w-full lg:w-2/5">
                                {pj.fields.description}
                            </p>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default SingleProjectDetails;
